// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-bingo-js": () => import("./../../../src/pages/bingo.js" /* webpackChunkName: "component---src-pages-bingo-js" */),
  "component---src-pages-bio-cv-js": () => import("./../../../src/pages/bio/cv.js" /* webpackChunkName: "component---src-pages-bio-cv-js" */),
  "component---src-pages-bio-index-en-js": () => import("./../../../src/pages/bio/index.en.js" /* webpackChunkName: "component---src-pages-bio-index-en-js" */),
  "component---src-pages-bio-index-es-js": () => import("./../../../src/pages/bio/index.es.js" /* webpackChunkName: "component---src-pages-bio-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labs-ada-js": () => import("./../../../src/pages/labs/ada.js" /* webpackChunkName: "component---src-pages-labs-ada-js" */),
  "component---src-pages-labs-index-js": () => import("./../../../src/pages/labs/index.js" /* webpackChunkName: "component---src-pages-labs-index-js" */),
  "component---src-pages-labs-io-js": () => import("./../../../src/pages/labs/io.js" /* webpackChunkName: "component---src-pages-labs-io-js" */),
  "component---src-pages-labs-live-emojing-js": () => import("./../../../src/pages/labs/live-emojing.js" /* webpackChunkName: "component---src-pages-labs-live-emojing-js" */),
  "component---src-pages-labs-live-emojing-midi-js": () => import("./../../../src/pages/labs/live-emojing-midi.js" /* webpackChunkName: "component---src-pages-labs-live-emojing-midi-js" */),
  "component---src-pages-labs-morse-js": () => import("./../../../src/pages/labs/morse.js" /* webpackChunkName: "component---src-pages-labs-morse-js" */),
  "component---src-pages-labs-multiplayer-js": () => import("./../../../src/pages/labs/multiplayer.js" /* webpackChunkName: "component---src-pages-labs-multiplayer-js" */),
  "component---src-pages-labs-tateti-js": () => import("./../../../src/pages/labs/tateti.js" /* webpackChunkName: "component---src-pages-labs-tateti-js" */),
  "component---src-pages-le-js": () => import("./../../../src/pages/le.js" /* webpackChunkName: "component---src-pages-le-js" */),
  "component---src-pages-log-js": () => import("./../../../src/pages/log.js" /* webpackChunkName: "component---src-pages-log-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-tw-js": () => import("./../../../src/pages/tw.js" /* webpackChunkName: "component---src-pages-tw-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-work-post-js": () => import("./../../../src/templates/work-post.js" /* webpackChunkName: "component---src-templates-work-post-js" */)
}

